import React from 'react';
import styled from 'styled-components';

import getNewPlaylistName from '../service/getNewPlaylistName';
import {connect} from 'react-redux';
import {createPlaylist} from '../state/actionCreators';
import {PrimaryButton} from './atoms';

const Form = styled.form``;
const PlaylistNameInput = styled.input`
    width: 400px;
`;

function CreatePlaylistForm({selectedTrackCount, createPlaylist}) {
    let playlistNameInputRef;

    return (
        <Form id="create-playlist-form">
            <div>Number of selected Tracks: {selectedTrackCount}</div>

            <PlaylistNameInput
                type="text"
                defaultValue={getNewPlaylistName()}
                ref={(ref) => (playlistNameInputRef = ref)}
            />
            <PrimaryButton type="button" onClick={() => createPlaylist(playlistNameInputRef.value)}>
                <i className="icon icon-list-add"></i>
                Create Playlist
            </PrimaryButton>
        </Form>
    );
}

export default connect(
    (state) => ({
        selectedTrackCount: state.selectedTrackCount
    }),
    {createPlaylist}
)(CreatePlaylistForm);
