/**
 *
 * @return {*}
 */
export function getListOfSelectedTrackUris(result) {
    return result.reduce((total, currentItem) => {
        const selectedUrisInItem = currentItem.tracks.filter((t) => t.selected).map((t) => t.uri);
        return total.concat(selectedUrisInItem);
    }, []);
}
