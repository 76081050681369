import React from 'react';
import styled from 'styled-components';

import Track from './Track';
import {ERROR_RED} from '../colors';

const TrackResultListItem = styled.li`
    position: relative;
    padding: 4px;
    border-radius: 5px;
    border: 1px solid transparent;

    &:hover {
        border: 1px solid #ccc;
    }
`;

const TrackList = styled.ul`
    list-style-type: none;
    margin: 0 0 40px 0;
`;

const NoResultErrorIcon = styled.i`
    color: ${ERROR_RED};
`;

const QueryInput = styled.input`
    width: 400px;
    border-bottom: 1px solid transparent;

    &:focus {
        border-color: rgb(209, 209, 209);
    }
`;

const ButtonRequery = styled.button`
    border: none;
`;
const Number = styled.span`
    position: absolute;
    right: 14px;
    top: 8px;
    font-size: 1.9rem;
`;

/**
 * result containing N matches for a given Track (-query)
 *
 * @param trackResult
 * @param {number} number Just the index in the list, beginning with 1      (i.e. corresponds to the line number in the input)
 * @param onPreview
 * @param onTrackToggled
 * @param onReQuery
 * @return {*}
 * @constructor
 */
function TrackResult({trackResult, number, onPreview, onTrackToggled, onReQuery}) {
    let queryInputRef;

    return (
        <TrackResultListItem>
            {trackResult.tracks.length < 1 && (
                <NoResultErrorIcon className="icon icon-cancel-circled" />
            )}

            <Number>{number}</Number>
            <span>
                <QueryInput
                    type="text"
                    defaultValue={trackResult.query}
                    ref={(ref) => (queryInputRef = ref)}
                />
                <ButtonRequery type="button" onClick={() => onReQuery(queryInputRef.value)}>
                    <i className="icon icon-arrows-ccw" />
                </ButtonRequery>
            </span>

            <TrackList>
                {trackResult.tracks.map((track) => (
                    <Track
                        key={track.id}
                        track={track}
                        onPreview={onPreview}
                        onTrackToggled={(trackId) => onTrackToggled(trackId)}
                    />
                ))}
            </TrackList>
        </TrackResultListItem>
    );
}

export default React.memo(
    TrackResult,
    (props, nextProps) => props.trackResult === nextProps.trackResult
);
