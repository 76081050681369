/**
 * The initial state that is loaded into the redux store on (client) application load.
 */
const INITIAL_STATE = {
    rawInput:
        process.env.NODE_ENV === 'development'
            ? `1.\tSantana - Oye como va
3.\tRick Astley - Together Forever`
            : 'Paste your playlist here...',
    user: undefined, // not logged in at startup
    analyzing: false,
    result: [],
    selectedTrackCount: 0,
    previewUrl: '',
    message: '',
    textPattern: ''
};

export default INITIAL_STATE;
