import {
    ANALYZED,
    ANALYZING,
    ANALYZING_SINGLE,
    ANALYZED_SINGLE,
    LOGGED_IN,
    PREVIEW_TRACK,
    TOGGLE_TRACK_SELECTED,
    RAW_INPUT_CHANGED,
    UPDATE_TEXT_PATTERN,
    LIST_CREATED
} from './actionTypes';

import toggleTrackSelected from './toggleTrackSelected';
import {countSelectedtracks} from './countSelectedTracks';

const rootReducer = (state = {}, action = {}) => {
    switch (action.type) {
        case ANALYZING: {
            return {
                ...state,
                analyzing: true,
                result: [],
                selectedTrackCount: 0,
                message: ''
            };
        }
        case ANALYZED: {
            return {
                ...state,
                analyzing: false,
                result: action.result,
                selectedTrackCount: countSelectedtracks(action.result),
                message: getAnalyzedMessage(action.result)
            };
        }
        case ANALYZING_SINGLE: {
            return {...state, analyzingSingle: true};
        }
        case ANALYZED_SINGLE: {
            const modifiedResult = state.result.map((trackResult) =>
                trackResult.id === action.trackResultId ? action.trackResult : trackResult
            );

            return {
                ...state,
                analyzingSingle: false,
                result: modifiedResult,
                selectedTrackCount: countSelectedtracks(modifiedResult)
            };
        }

        case LOGGED_IN: {
            return {...state, user: action.user};
        }

        case TOGGLE_TRACK_SELECTED: {
            const modifiedResult = toggleTrackSelected(
                state.result,
                action.trackResultId,
                action.trackId
            );
            return {
                ...state,
                result: modifiedResult,
                selectedTrackCount: countSelectedtracks(modifiedResult)
            };
        }
        case PREVIEW_TRACK: {
            return {
                ...state,
                previewUrl: action.url
            };
        }
        case RAW_INPUT_CHANGED: {
            return {
                ...state,
                rawInput: action.rawInput
            };
        }
        case UPDATE_TEXT_PATTERN: {
            return {
                ...state,
                textPattern: action.textPattern
            };
        }
        case LIST_CREATED: {
            return {
                ...state,
                message: `New Playlist ${action.list.name} created`
            };
        }
        case '@@redux/INIT':
            return state;
        default:
            return state;
    }
};

export default rootReducer;

function getAnalyzedMessage(result) {
    const unmatched = result.reduce((total, trackResult) => {
        if (trackResult.tracks.length < 1) {
            total += 1;
        }
        return total;
    }, 0);

    return `Analyzed ${result.length} Songs. ${unmatched} unmatched Songs...`;
}
