import {inputToLineArray, isGarbageLine} from './playlistInputHelpers';
import searchTracks from './searchTracks';
import mapSearchResult from './mapSearchResult';

export default function analyzePlaylist(inputText, accessToken) {
    const inputLines = inputToLineArray(inputText)
        .filter((line) => !isGarbageLine(line))
        .map((line) => {
            line = line.replace(/\(?\d{1,3}\.? Week\)?/, ' ');
            line = line.replace(/([ |\t]*)(\(?\d{1,3}\.?\)?)([ |\t]*)/g, ' ');
            line = line.replace(/^([ |\t]*)/, '');
            return line;
        });

    const searchPromises = inputLines.map((line) => searchTracks(accessToken, line));

    return Promise.all(searchPromises).then(mapSearchResult);
}
