import React from 'react';
import styled from 'styled-components';

import {SPOTIFY_GREEN, WARNING_ORANGE} from '../colors';

const imgSize = 32;

const AlbumCover = styled.div.attrs((props) => ({
    style: {
        backgroundImage: `url(${props.src}`,
        width: imgSize,
        height: imgSize
    }
}))`
    display: inline-block;
    background: transparent;
    cursor: ${({hasPreview}) => (hasPreview ? 'pointer' : 'inherit')};
`;

const Flexer = styled.div`
    display: inline-block;
    width: calc(100% - ${imgSize + 160}px);

    span {
        padding: 0 8px;
    }
`;

const Title = styled.span`
    font-weight: 600;
`;

const LowPopularityWarningIcon = styled.i`
    color: ${WARNING_ORANGE};
`;
const POPULARITY_WARNING_THRESHOLD = 40;

function Track({track, onPreview, onTrackToggled}) {
    return (
        <li>
            <TrackToggle onClick={() => onTrackToggled(track.id)} selected={track.selected} />

            <AlbumCover
                src={track.albumImage}
                hasPreview={!!track.preview}
                onClick={() => onPreview(track.preview)}
            />
            <Flexer>
                <div>
                    <Title>{track.title}</Title>
                </div>
                <div>
                    <span>{track.artist}</span>
                    <span>{track.album}</span>
                </div>
            </Flexer>
            {track.selected && track.popularity < POPULARITY_WARNING_THRESHOLD && (
                <LowPopularityWarningIcon className="icon icon-attention" />
            )}
            <Popularity value={track.popularity} />
        </li>
    );
}

export default React.memo(Track, (props, nextProps) => props.track === nextProps.track);

const PopularityContainer = styled.div`
    display: inline-block;
    border: 1px solid #ccc;
    width: 100px;
    padding: 0;
    height: 12px;
`;
const PopularityBar = styled.div`
    width: ${({value}) => value}px;
    background: ${SPOTIFY_GREEN};
    height: 12px;
`;

function Popularity({value}) {
    return (
        <PopularityContainer>
            <PopularityBar value={value} />
        </PopularityContainer>
    );
}

const TrackToggleI = styled.i.attrs(({selected}) => ({
    style: {
        color: selected ? SPOTIFY_GREEN : 'inherit'
    }
}))`
    display: inline-block;
    width: 16px;
    height: 16px;
    cursor: pointer;
    margin-right: 16px;
    font-size: larger;
`;

function TrackToggle({selected, onClick}) {
    return (
        <TrackToggleI
            selected={selected}
            onClick={onClick}
            className={`icon ${selected ? 'icon-check-1' : 'icon-check-empty'}`}
        />
    );
}
