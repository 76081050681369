import axios from 'axios';

/**
 *
 * @param userId
 * @param name
 * @param {String[]} tracks List of track uris
 * @param accessToken
 * @return {Promise<any | never>}
 */
export default function createPersistentPlaylist(userId, name, tracks, accessToken) {
    return createList(userId, name, accessToken).then((list) =>
        addTracks(userId, list.id, tracks, accessToken).then(() => list)
    );
}

function createList(userId, name, accessToken) {
    return axios
        .post(
            `https://api.spotify.com/v1/users/${userId}/playlists`,
            {
                name: name,
                public: false
            },
            {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            }
        )

        .then(mapList);
}

function mapList({data}) {
    const url = data.external_urls ? data.external_urls.spotify : '';
    return {name: data.name, id: data.id, url};
}

function addTracks(userId, playlistId, tracks, accessToken) {
    return axios.post(
        `https://api.spotify.com/v1/users/${userId}/playlists/${playlistId}/tracks`,
        {uris: tracks},
        {
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        }
    );
}
