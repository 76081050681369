import React from 'react';

import '../../node_modules/codemirror/lib/codemirror.css';
import {inputToLineArray} from '../service/playlistInputHelpers';

// document.cmi.

class CodeMirror extends React.Component {
    constructor(props) {
        super(props);
        this.cmMarkers = [];
    }

    getCodeMirrorInstance() {
        return this.props.codeMirrorInstance || require('codemirror');
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.codeMirror &&
            this.props.value !== undefined &&
            this.props.value !== prevProps.value &&
            normalizeLineEndings(this.codeMirror.getValue()) !==
                normalizeLineEndings(this.props.value)
        ) {
            if (prevProps.preserveScrollPosition) {
                const prevScrollPosition = this.codeMirror.getScrollInfo();
                this.clearMarkers();
                this.codeMirror.scrollTo(prevScrollPosition.left, prevScrollPosition.top);
            } else {
                this.codeMirror.setValue(this.props.value);
                this.clearMarkers();
            }
        }

        if (this.codeMirror && this.props.markPattern !== prevProps.markPattern) {
            this.markMatches();
        }
    }

    clearMarkers() {
        console.log('clearing markers', this.cmMarkers.length);
        this.cmMarkers.forEach((m) => m.clear());
        this.cmMarkers = [];
    }

    markMatches() {
        this.clearMarkers();

        const regEx = new RegExp(this.props.markPattern, 'g');
        const lines = inputToLineArray(this.props.value);

        const doc = this.codeMirror.getDoc();
        const markerOptions = {className: 'highlighted'};

        lines.forEach((line, lineIndex) => {
            const matches = line.matchAll(regEx);

            for (const match of matches) {
                const newMarker = doc.markText(
                    {
                        line: lineIndex,
                        ch: match.index
                    },
                    {
                        line: lineIndex,
                        ch: match.index + match[0].length
                    },
                    markerOptions
                );

                this.cmMarkers.push(newMarker);
            }
        });
    }

    componentDidMount() {
        const codeMirrorInstance = this.getCodeMirrorInstance();

        this.codeMirror = codeMirrorInstance.fromTextArea(this.textareaNode, this.props.options);
        this.codeMirror.on('change', this.codemirrorValueChanged.bind(this));
        this.codeMirror.on('cursorActivity', this.cursorActivity.bind(this));
        this.codeMirror.on('focus', this.focusChanged.bind(this, true));
        this.codeMirror.on('blur', this.focusChanged.bind(this, false));
        this.codeMirror.on('scroll', this.scrollChanged.bind(this));
        this.codeMirror.setValue(this.props.defaultValue || this.props.value || '');
        document.cmi = this.codeMirror;
    }

    componentWillUnmount() {
        // is there a lighter-weight way to remove the cm instance?
        if (this.codeMirror) {
            this.codeMirror.toTextArea();
        }
    }

    focusChanged(focused) {
        this.setState({
            isFocused: focused
        });
        this.props.onFocusChange && this.props.onFocusChange(focused);
    }

    cursorActivity(cm) {
        this.props.onCursorActivity && this.props.onCursorActivity(cm);
    }

    scrollChanged(cm) {
        this.props.onScroll && this.props.onScroll(cm.getScrollInfo());
    }

    codemirrorValueChanged(doc, change) {
        if (this.props.onChange && change.origin !== 'setValue') {
            this.props.onChange(doc.getValue(), change);
        }
    }

    render() {
        return (
            <textarea
                ref={(ref) => (this.textareaNode = ref)}
                name={this.props.name || this.props.path}
                defaultValue={this.props.value}
                autoComplete="off"
                autoFocus={this.props.autoFocus}
            />
        );
    }
}

function normalizeLineEndings(str) {
    if (!str) return str;
    return str.replace(/\r\n|\r/g, '\n');
}

export default CodeMirror;
