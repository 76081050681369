import axios from 'axios';

export default function searchTracks(accessToken, query, limit = 3) {
    const queryUrl =
        'https://api.spotify.com/v1/search?market=from_token&q=' +
        encodeURIComponent(query) +
        '&type=track&limit=' +
        limit;

    return axios
        .get(queryUrl, {
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        })
        .then(({data}) => {
            if (data && data.tracks) {
                data.tracks.items.sort(popularityComparator);
                return {
                    tracks: data.tracks.items,
                    query
                };
            } else {
                return {tracks: [], query};
            }
        });
}

function popularityComparator(trackOne, trackTwo) {
    if (trackOne.popularity < trackTwo.popularity) {
        return 1;
    }
    if (trackOne.popularity > trackTwo.popularity) {
        return -1;
    }
    return 0;
}
