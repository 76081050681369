const PATTERN_DURATION_LINE = /^([ |\t]*)(\d*)[:|.]*(\d*)([ |\t]*)(\d*)$/;

export function isGarbageLine(line) {
    if (isBlank(line)) {
        return true;
    }

    return PATTERN_DURATION_LINE.test(line);
}

export function isBlank(str) {
    return !str || /^\s*$/.test(str);
}

export function inputToLineArray(input) {
    if (!input) {
        return [];
    }
    return input.split('\n');
}

export function joinLines(lineArray) {
    return lineArray.join('\n');
}

export function removeEvenLines(input) {
    const oddsOnly = inputToLineArray(input).filter((e, i) => i % 2 === 0);
    return joinLines(oddsOnly);
}

export function removeOddLines(input) {
    const evenOnly = inputToLineArray(input).filter((e, i) => i % 2 !== 0);
    return joinLines(evenOnly);
}

export function removeRegexMatches(input, pattern) {
    return input.replace(new RegExp(pattern, 'g'), '');
}

export function countLines(input) {
    return inputToLineArray(input).length;
}
