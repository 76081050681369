import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';

import {autoLogin, login} from '../state/actionCreators';

import RawInputForm from './RawInputForm';
import AnalysisResult from './AnalysisResult';
import UserInfo from './UserInfo';
import CreatePlaylistForm from './CreatePlaylistForm';

const AppContainer = styled.div`
    margin: 0 auto;
    width: 900px;
`;

function App({analyzing, user, previewUrl, message, autoLogin, login}) {
    let audioElementRef;

    useEffect(() => {
        autoLogin();
    }, [autoLogin]); // do only once,  at mounting component

    useEffect(() => {
        if (previewUrl) {
            audioElementRef.play();
        }
    }, [previewUrl, audioElementRef]);

    return (
        <AppContainer id="app">
            <h1>Spotify Playlist Imorter</h1>

            {!user && <button onClick={() => login()}>Login to Spotify</button>}

            {user && (
                <React.Fragment>
                    <UserInfo user={user} />

                    <RawInputForm />

                    <audio src={previewUrl} ref={(ref) => (audioElementRef = ref)} />

                    {analyzing && <div>Analyzing...</div>}
                    {message && <div>{message}</div>}

                    <CreatePlaylistForm />
                    <AnalysisResult />
                </React.Fragment>
            )}
        </AppContainer>
    );
}

export default connect(
    (state) => ({
        analyzing: state.analyzing,
        user: state.user,
        previewUrl: state.previewUrl,
        message: state.message
    }),
    {autoLogin, login}
)(App);
