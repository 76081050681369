import axios from 'axios';

const CLIENT_ID = '6ab964e0542e4da4b9ae890d0f624978';
const LOCAL_STORAGE_STATE_KEY = 'spotify_auth_state';

/**
 * Obtains parameters from the hash of the URL
 * @return Object
 */
function getHashParams() {
    var hashParams = {};
    var e,
        r = /([^&;=]+)=?([^&;]*)/g,
        q = window.location.hash.substring(1);
    while ((e = r.exec(q))) {
        hashParams[e[1]] = decodeURIComponent(e[2]);
    }
    return hashParams;
}

/**
 * Generates a random string containing numbers and letters
 * @param  {number} length The length of the string
 * @return {string} The generated string
 */
function generateRandomString(length) {
    let text = '';
    let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (var i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
}

export async function doAutoLogin() {
    const params = getHashParams();

    const access_token = params.access_token,
        state = params.state,
        storedState = localStorage.getItem(LOCAL_STORAGE_STATE_KEY);

    const dev = process.env.NODE_ENV === 'development';
    if (access_token && (state == null || state !== storedState) && !access_token && dev) {
        console.error('There was an error during the authentication');
        return undefined;
    } else {
        localStorage.removeItem(LOCAL_STORAGE_STATE_KEY);
        if (access_token) {
            return axios
                .get('https://api.spotify.com/v1/me', {
                    headers: {
                        Authorization: 'Bearer ' + access_token
                    }
                })
                .then(({data}) => ({
                    accessToken: access_token,
                    id: data.id,
                    displayName: data.display_name,
                    email: data.email,
                    country: data.country,
                    product: data.product // e.g. "premium"
                }));
        } else {
            console.log('not logged in');
            return undefined;
        }
    }
}

export function doLogin() {
    const redirect_uri =
        process.env.NODE_ENV === 'development'
            ? 'http://localhost:3000'
            : 'https://plimporter.trentini.ch'; // Your redirect uri

    const state = generateRandomString(16);

    localStorage.setItem(LOCAL_STORAGE_STATE_KEY, state);
    const scope = 'user-read-private user-read-email';

    let url = 'https://accounts.spotify.com/authorize';
    url += '?response_type=token';
    url += '&client_id=' + encodeURIComponent(CLIENT_ID);
    url += '&scope=' + encodeURIComponent(scope);
    url += '&redirect_uri=' + encodeURIComponent(redirect_uri);
    url += '&state=' + encodeURIComponent(state);

    window.location = url;
}
