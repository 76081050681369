export const ANALYZING = 'ANALYZING';
export const ANALYZED = 'ANALYZED';
export const ANALYZING_SINGLE = 'ANALYZING_SINGLE';
export const ANALYZED_SINGLE = 'ANALYZED_SINGLE';
export const LOGGED_IN = 'LOGGED_IN';
export const TOGGLE_TRACK_SELECTED = 'TOGGLE_TRACK_SELECTED';
export const PREVIEW_TRACK = 'PREVIEW_TRACK';
export const RAW_INPUT_CHANGED = 'RAW_INPUT_CHANGED';
export const UPDATE_TEXT_PATTERN = 'UPDATE_TEXT_PATTERN';
export const LIST_CREATED = 'LIST_CREATED';
