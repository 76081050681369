import {doAutoLogin, doLogin} from '../service/SpotifyAuthService';
import analyzePlaylist from '../service/analyzePlaylist';
import createPersistentPlaylist from '../service/createPersistentPlaylist';
import {getListOfSelectedTrackUris} from './getListOfSelectedTrackUris';
import analyzeSingle from '../service/analyzeSingle';

import {
    ANALYZED,
    ANALYZING,
    ANALYZED_SINGLE,
    ANALYZING_SINGLE,
    LOGGED_IN,
    TOGGLE_TRACK_SELECTED,
    PREVIEW_TRACK,
    RAW_INPUT_CHANGED,
    LIST_CREATED,
    UPDATE_TEXT_PATTERN
} from './actionTypes';

export const analyze = () => (dispatch, getState) => {
    const {user, rawInput} = getState();
    if (!user) {
        return;
    }

    dispatch({
        type: ANALYZING
    });

    analyzePlaylist(rawInput, user.accessToken)
        .then((result) => {
            dispatch({
                type: ANALYZED,
                result
            });
        })
        .catch((err) => console.error(err));
};

export const reQuery = (trackResultId, newQuery) => (dispatch, getState) => {
    const user = getState().user;
    if (!user) {
        return;
    }

    dispatch({
        type: ANALYZING_SINGLE
    });

    analyzeSingle(newQuery, user.accessToken).then((trackResult) => {
        dispatch({
            type: ANALYZED_SINGLE,
            trackResultId,
            trackResult
        });
    });
};

export const rawInputChanged = (rawInput) => ({type: RAW_INPUT_CHANGED, rawInput});
export const updateTextPattern = (textPattern) => ({type: UPDATE_TEXT_PATTERN, textPattern});

export const autoLogin = () => (dispatch) => {
    doAutoLogin().then((user) => dispatch({type: LOGGED_IN, user}));
};
export const login = () => () => {
    doLogin();
};

export const toggleTrackSelected = (trackResultId, trackId) => ({
    type: TOGGLE_TRACK_SELECTED,
    trackResultId,
    trackId
});

export const previewTrack = (url) => ({
    type: PREVIEW_TRACK,
    url
});

export const createPlaylist = (plName) => (dispatch, getState) => {
    const {user, result} = getState();
    if (!user) {
        return;
    }

    const selectedTrackUris = getListOfSelectedTrackUris(result);

    if (selectedTrackUris.length > 0) {
        createPersistentPlaylist(user.id, plName, selectedTrackUris, user.accessToken)
            .then((list) => dispatch({type: LIST_CREATED, list}))
            .catch((err) => console.error(err));
    }
};
