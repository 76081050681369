import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';

import {PrimaryButton} from './atoms';
import {analyze, rawInputChanged, updateTextPattern} from '../state/actionCreators';
import CodeMirror from './CodeMirror';
import {
    countLines,
    removeEvenLines,
    removeOddLines,
    removeRegexMatches
} from '../service/playlistInputHelpers';

const Form = styled.form``;

const ButtonContainer = styled.div`
    display: flex;
    > button {
        margin-right: 22px;
    }
`;
const RegexInput = styled.input`
    width: 400px;
`;

function RawInputForm({rawInput, textPattern, rawInputChanged, analyze, updateTextPattern}) {
    let regexPatternInputRef;

    const options = {
        lineNumbers: true
    };

    return (
        <Form id="raw-input-form">
            <CodeMirror
                value={rawInput}
                onChange={rawInputChanged}
                options={options}
                markPattern={textPattern}
            />

            <div>Number of Lines in input: {countLines(rawInput)}</div>

            <ButtonContainer>
                <button type="button" onClick={() => rawInputChanged(removeEvenLines(rawInput))}>
                    <i className="icon icon-trash"></i> 2-4-6...
                </button>
                <button type="button" onClick={() => rawInputChanged(removeOddLines(rawInput))}>
                    <i className="icon icon-trash"></i> 1-3-5...
                </button>
            </ButtonContainer>
            <div>
                <RegexInput
                    type="text"
                    defaultValue={textPattern}
                    ref={(ref) => (regexPatternInputRef = ref)}
                    placeholder="Regex pattern"
                />
                <button type="button" onClick={() => updateTextPattern(regexPatternInputRef.value)}>
                    <i className="icon icon-target"></i>
                </button>
                <button
                    type="button"
                    onClick={() =>
                        rawInputChanged(removeRegexMatches(rawInput, regexPatternInputRef.value))
                    }
                >
                    <i className="icon icon-trash"></i>
                </button>
            </div>
            <PrimaryButton type="button" onClick={analyze}>
                <i className="icon icon-tools"></i>
                Analyze
            </PrimaryButton>
        </Form>
    );
}

export default connect(
    (state) => ({
        rawInput: state.rawInput,
        textPattern: state.textPattern
    }),
    {analyze, rawInputChanged, updateTextPattern}
)(RawInputForm);
