import React from 'react';
import styled from 'styled-components';

import TrackResult from './TrackResult';
import {connect} from 'react-redux';
import {previewTrack, reQuery, toggleTrackSelected} from '../state/actionCreators';

const ResultList = styled.ul`
    list-style-type: none;
`;

/**
 * The whole list of results
 *
 * @param result
 * @param previewTrack
 * @param toggleTrackSelected
 * @param reQuery
 * @return {*}
 * @constructor
 */
function AnalysisResult({result, previewTrack, toggleTrackSelected, reQuery}) {
    return (
        <ResultList>
            {result.map((trackResult, index) => (
                <TrackResult
                    number={index + 1}
                    key={trackResult.id}
                    trackResult={trackResult}
                    onPreview={previewTrack}
                    onTrackToggled={(trackId) => toggleTrackSelected(trackResult.id, trackId)}
                    onReQuery={(newQuery) => reQuery(trackResult.id, newQuery)}
                />
            ))}
        </ResultList>
    );
}

export default connect(
    (state) => ({
        result: state.result
    }),
    {reQuery, toggleTrackSelected, previewTrack}
)(AnalysisResult);
