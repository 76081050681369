/**
 *
 * @param result
 * @param trackResultId
 * @param trackId
 * @return {*}
 */
export default function toggleTrackSelected(result, trackResultId, trackId) {
    return result.map((trackResult) =>
        trackResult.id === trackResultId
            ? toggleTrackSelectedInTrackResult(trackResult, trackId)
            : trackResult
    );
}

function toggleTrackSelectedInTrackResult(trackResult, trackId) {
    const modifiedTracksArray = trackResult.tracks.map((track) =>
        track.id === trackId
            ? {
                  ...track,
                  selected: !track.selected
              }
            : track
    );

    return {
        ...trackResult,
        tracks: modifiedTracksArray
    };
}
