/**
 *
 * @return {*}
 */
export function countSelectedtracks(result) {
    return result.reduce((total, currentItem) => {
        const selectedUrisInItem = currentItem.tracks.filter((t) => t.selected);
        total += selectedUrisInItem.length;
        return total;
    }, 0);
}
