import React from 'react';
import styled from 'styled-components';

const UserInfoContainer = styled.div``;

function UserInfo({user}) {
    return (
        <UserInfoContainer>
            Logged in as {user.displayName} in {user.country}
        </UserInfoContainer>
    );
}

export default UserInfo;
