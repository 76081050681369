import uuid from 'uuid/v4';

export default function mapResult(result) {
    return result.map(mapSingleResult);
}

export function mapSingleResult(trackResult) {
    return {
        id: uuid(),
        query: trackResult.query,
        tracks: trackResult.tracks.map(mapTrack)
    };
}

function mapTrack(track, index) {
    return {
        selected: index === 0, // preselect each first track
        album: track.album ? track.album.name : '',
        albumImage: track.album ? selectImage(track.album.images) : '',
        albumArtist: track.album ? selectArtist(track.album.artists) : '',
        playable: track.is_playable,
        title: track.name,
        artist: selectArtist(track.artists),
        id: track.id,
        popularity: track.popularity,
        preview: track.preview_url,
        duration: track.duration_ms,
        uri: track.uri
    };
}

function selectArtist(artists) {
    if (!artists || artists.length < 1) {
        return '';
    }
    return artists[0].name;
}

function selectImage(images) {
    if (!images || images.length < 1) {
        return '';
    }

    // sort according to "height" of image, ASC
    images.sort((imgOne, imgTwo) => {
        if (imgOne.height < imgTwo.height) {
            return -1;
        }
        if (imgOne.height > imgTwo.height) {
            return 1;
        }
        return 0;
    });

    // pick the smallest one
    return images[0].url;
}
